
import i18n from "@/i18n";
import { ICredentialType } from "@/interfaces/api/v2/credential-type.interface";
import { unwrapTranslations } from "@/utils/entity";
import { Component, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";

@Component({
  computed: {
    ...mapGetters(["credentialTypesByIssuer"]),
  },
})
export default class OrgBookData extends Vue {
  formattedDescription(type: ICredentialType): string {
    return (
      // TODO: change to type.schema_label?.[i18n.locale]?.description after update to backend
      unwrapTranslations(type.schema_label)?.[i18n.locale]?.description ||
      type?.description ||
      ""
    );
  }
}
